import Footer from "../components/footer";
import Header from "../components/header";
import { Container, Typography, Box, Paper } from "@mui/material";

const About = () => {
    return (
        <>
            <Header />
            <Container maxWidth="md" sx={{ py: 4 }}>
                <Paper elevation={3} sx={{ p: 4, bgcolor: 'background.paper' }}>
                    <Typography variant="h5" gutterBottom align="center" fontWeight="bold">
                        “UNITY” Компаниясы жөнүндө
                    </Typography>
                    <Typography variant="body1" paragraph>
                        “UNITY” (биримдик) компаниясынын турак жай менен тейлөө тартиби Кыргыз Республикасынын аймагынын 
                        ичинде гана уюштурулуп, жана жарандары менен гана чектелген комерциялык эмес кооперативтик уюм. 
                        Кооперативтик башка компаниялардан уставдык эреже схемасы жана талаптары менен айырмаланат.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Компаниянын уюштуруучулары жана мүчөлөрү болуп эки тараптуу иш жүргүзүлөт. Жөнөкөй жаран мүчөлүкө 
                        катталуу үчүн бир жолку төлөм жүргүзүү зарыл. Көпчүлүктүн мына ушундай бир жолку төлөмдөрүнүн негизинде 
                        мүчөлөрдүн каржылык абалына карап, турак жай менен тейленет. Бул процесс биздин платформада онлайн көрсөтүлөт. 
                        Компания өз мүчөлөрүн тейлөө менен гана иш алып барат.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Каттоого алынган адам тизмеге кошулуп, компания жетекчилиги тарабынан тастыкталган (номерлүү) күбөлүгү 
                        менен кооперативтин мүчөсү болуп эсептелет. Жөнөкөй жаран турак жайлуу болуу максаттан тышкары, колдоо көрсөтүү 
                        же уюштуруучулардын катарына кошулуп жетекчиликке талапкер болуу максатта кошулса болот. 
                        Кыргыз Республикасынын жарандыгы экенин тастыктаган күбөлүгү (паспорт) бар адам мүчө боло алат.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Компаниянын жетекчиси Президент аталып уюштуруучулар тарабынан бир жылдык мөөнөткө шайланат, жана ар бир 
                        уюштуруучу жетекчи болууга талапкер боло алат. Уюштуруучулардын жүз пайыз добушу менен жетекчи шайлана алат.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Эгерде кимдер бирөөнү уюштуруучулуктун курамынан чететүү максат каралган болсо анда жүз пайыз 
                        уюштуруучулардын добушу менен чететилет. Компаниянын жетекчиси татыктуулугу менен кийинки жылдарга да 
                        улантылып шайланууга акысы бар.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Компаниянын мүчөлөрү уюштуруучулардын катарына кошулуу үчүн Компанияга кеминде 10 адамдын мүчө болуп 
                        катталуусуна себепкер болушу керек. Уюштуруучулардын мүчөлөрдөн айырмаланып компаниянын жоопкерчилиги жүктөлгөн.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Башталышында канчалык жарандардын катталуусу көп болсо ошончолук тейлөө тез болуп компаниянын иш жүргүзүү 
                        процеси күчөйт. Негизги максат принципке ылайык жарандарыбыздын турак жайды ижарага алып жашагандардын санын кыскартуу болуп эсептелет.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Жарандардын бул компанияга катталуусу менен турак жайды ижарага берүү, ижарага алуу, ижарадан пайда көрүү 
                        максаттагы адамдардын саны кыскарат. Бул нерсе Кыргыз Республикасынын аймагындагы турак жайсыз жана 
                        турак жайлуу жарандардын ортосундагы ажырымды жоюуга тең салмактуулугун сактап, адилеттүү тейлөө тартибин 
                        ишке ашырат.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Компаниянын биринчи уюштуруучулары компаниянын негиздөөчүлөрү болуп эсептелет.
                    </Typography>
                </Paper>
            </Container>
            <Footer />
        </>
    );
}

export default About;
